@import "./assets/fonts/Satoshi_Complete/Fonts/WEB/css/satoshi.css";
@import "./assets/fonts/fonts.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
  /* apply neutral-900 */
  background-color: rgb(10 10 10);
}

::selection {
  background-color: #d623b8; /* bg-violet-eggplant-500 */
  color: #ffffff; /* text-neutral-900 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
